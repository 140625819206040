<template>
    <div class="info">
      <top-bar :title="'提交报修'" :left="true"></top-bar>

      <van-popup v-model="sourceShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.sourceList"  value-key="label"  @confirm="sourceConfirm"  @cancel="sourceShow = false"/>
      </van-popup>

      <van-popup v-model="categoryshow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.categoryList"  value-key="label"  @confirm="categoryConfirm"  @cancel="categoryshow = false"/>
      </van-popup>

      <van-popup v-model="CommunityShow"  position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
      </van-popup>

      <van-popup v-model="positionShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.positionList" value-key="label"  @confirm="positionConfirm"  @cancel="positionShow = false"/>
      </van-popup>

      <van-popup v-model="BuildingShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区或切换小区</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
      </van-popup>

      <van-popup v-model="unitShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到单元信息,请先选择楼幢或切换楼幢</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
      </van-popup>

      <van-popup v-model="houseShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到房屋信息,请先选择单元或切换单元</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.houseList" value-key="label"  @confirm="houseConfirm"  @cancel="houseShow = false"/>
      </van-popup>
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">报修信息</span>
          </template>
        </van-cell>
        <van-cell title="报修来源" :value="selectMsg.source"  @click="sourceShow = true" is-link/>
        <van-cell title="报修类别" :value="selectMsg.category"  @click="categoryshow = true" is-link/>
        <van-cell title="报修性质" :value="selectMsg.position"  @click="positionShow = true" is-link/>
        <van-cell title="所在小区" :value="selectMsg.Community" @click="CommunityShow = true" is-link/>
        <van-cell title="所在楼幢" :value="selectMsg.Building"  @click="BuildingShow = true" is-link/>
        <van-cell title="所在单元" :value="selectMsg.unit" @click="unitShow = true" v-if="selectMsg.position=='居民家庭维修'&&selectMsg.unit" is-link/>
        <van-cell title="所在房屋" :value="selectMsg.house" @click="houseShow = true" v-if="selectMsg.position=='居民家庭维修'&&selectMsg.house" is-link/>
        <van-field v-model="selectMsg.description" rows="3" :border="false" clearable clear-trigger="always" autosize type="textarea" placeholder="报修描述" class="left"/>
        <van-row class="uploadBox">
          <van-col :span="24">
            <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead"/>
          </van-col>
        </van-row>
      </van-cell-group>
      <van-row class="btns">
        <van-col :span="24">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'
import {getbelongSubArea, getDictTree} from '@/utils/common'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      file:{},
      detailData: {},
      sourceShow: false,
      categoryshow: false,
      CommunityShow: false,
      positionShow: false,
      BuildingShow: false,
      unitShow: false,
      houseShow: false,
      selectMsg: {
        category: '请选择',
        Community: '请选择',
        position: '请选择',
        Building: '请选择',
        house: '请选择',
        unit: '请选择',
        description: '',
        source: ''
      },
      submitData: {
        buildingId:'',
        repairDes:'',
        deleteFiles: [],
        fileMessages:[],
        publicPlace:'',
        houseId:'',
        source: 1,
        orgId:0,
        subarea:'',
        repairType:'',
        unitId:'',
        userId:'',
        source: ''
      },
      selectData: {
        sourceList: [{label: "上级督查", value: 3}, {label: "社区自查", value: 1}],
        categoryList: [],
        CommunityList: [],
        positionList: [{ label: "公共区域", value: "1" }, { label: "居民家庭维修", value: "0" }],
        BuildingList: [],
        unitList: [],
        houseList: []
      },
      fileList: [],
    }
  },
  computed:{...mapState(['repairData'])},
  components :{
    topBar
  },
  methods: {
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.submitData.fileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", 'files/repair/temporary');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.submitData.fileMessages.push(data.fileMessage)
            return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
      })
    },
    init () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.CommunityList = data.subAreaList
          } else {
          this.$toast.fail(data.msg);
          }
        })

      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'repairType'
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.categoryList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
      })
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/commonApp/repair/info/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.repairData.id
        })
      }).then(({data})=> {
          if (data.code == 0) {
            this.submitData = {
              deleteFiles:[],
              fileMessages: [],
              houseId:data.repairInfoEntityVO.houseId ||   "",
              id:data.repairInfoEntityVO.id + '' ||   "",
              buildingId:data.repairInfoEntityVO.buildingId || '',
              newFileMessages: [],
              publicPlace: data.repairInfoEntityVO.publicPlace + '' ||  "",
              repairDes:data.repairInfoEntityVO.repairDes ||   "",
              repairType: data.repairInfoEntityVO.repairType ||  '',
              source: data.repairInfoEntityVO.source ||  '',
              subarea: data.repairInfoEntityVO.subarea ||  '',
              unitId:data.repairInfoEntityVO.unitId ||   ""
            }
            this.submitData.fileMessages = this.submitData.newFileMessages
            let Building = data.repairInfoEntityVO.buildingName
            let unit = data.repairInfoEntityVO.unitName
            let house = data.repairInfoEntityVO.houseName
            // if(data.repairInfoEntityVO.fullLocation){
            //   let list = data.repairInfoEntityVO.fullLocation.split('-')
            //   Building = list[1]
            //   unit = list[2]
            //   house = list[3]
            // }
            this.selectMsg = {
               source: data.repairInfoEntityVO.source == 1 ? '社区自查': data.repairInfoEntityVO.source == 2 ? '居民反馈': data.repairInfoEntityVO.source == 3
                ? '上级督查' : '请选择',
               category:data.repairInfoEntityVO.repairTypeStr || '请选择',      //问题类别
               Community:data.repairInfoEntityVO.subareaName || '请选择',     //报修性质
               position:data.repairInfoEntityVO.publicPlaceStr || '请选择',      //所在小区
               Building,      //所在楼幢
               house,     //所在单元
               unit,      //所在房屋
               description:data.repairInfoEntityVO.repairDes || ''      //问题描述
            }
            if (data.repairInfoEntityVO.fileDisplayVOs && data.repairInfoEntityVO.fileDisplayVOs.length != 0) {
            data.repairInfoEntityVO.fileDisplayVOs.forEach(item=> {
              let u;
              if (item.fileType == 'image') {
                u = getImageStream(item.relativeUrl)
                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.fileType,
                  url: u,
                  realUrl: item.relativeUrl.replace(/\\/g, "/")
                }
                this.fileList.push(url);
              }
            })
            }
            this.detailData = data.repairInfoEntityVO
            this.$http({
              url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
              method: 'post',
              params: this.$http.adornParams({
                subArea: this.submitData.subarea
              }, false)
            }).then(({data})=> {
              if (data.code == 0) {
                this.selectData.BuildingList = data.buildings
              } else {
                this.$toast.fail(data.msg);
              }
            })
            if (this.selectMsg.unit) {
              this.getUnitList(data.repairInfoEntityVO.buildingId)
              this.getHouseList(data.repairInfoEntityVO.unitId, 2)
            } else {
              this.getHouseList(data.repairInfoEntityVO.buildingId, 1)
            }
          this.$toast.clear()
          } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
      })
    },
    sourceConfirm (value) {
      if (value){
      this.selectMsg.source = value.label
      this.submitData.source = value.value
      }
      this.sourceShow = false
    },
    submit () {
      if (this.selectMsg.source === '请选择') {return this.$toast.fail('失败:请先选择报修来源');}
      if (this.selectMsg.category === '请选择') {return this.$toast.fail('失败:请先选择事件类型');}
      if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      if (this.selectMsg.position === '请选择') {return this.$toast.fail('失败:请先选择位置');}
      if (this.selectMsg.Building === '请选择') {return this.$toast.fail('失败:请先选择楼幢');}
      if ( this.selectMsg.unit === '请选择' && this.submitData.publicPlace == 2) {return this.$toast.fail('失败:请先选择单元');}
      if ( this.selectMsg.house === '请选择' && this.submitData.publicPlace == 2) {return this.$toast.fail('失败:请先选择房屋');}
      if (this.selectMsg.description === '') {return this.$toast.fail('失败:请先输入问题描述');}
      this.submitData.repairDes = this.selectMsg.description
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/commonApp/repair/info/save'),
        method: 'post',
        data : this.submitData
      }).then(({data})=> {
          if (data.code == 0) {
          this.$toast.success ('新增成功');
          this.$router.go(-1)
          } else {
          this.$toast.fail(data.msg);
          }
        })
    }, 
    getUnitList (value) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
        method: 'post',
        params: this.$http.adornParams({
          buildingId: value
        }, false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.unitList = data.units
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getHouseList (value, type) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
        method: 'post',
        params: this.$http.adornParams({
          upId: value,
          bOru: type
        }, false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.houseList = data.houseOptions
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    categoryConfirm (value) {
      if (value){
      this.selectMsg.category = value.label
      this.submitData.repairType = value.value
      }
      this.categoryshow = false
    },
    // 小区选择
    CommunityConfirm (value) {
      console.log(value);
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        this.selectMsg.position = '请选择'
        this.selectMsg.Building = '请选择'
        this.selectMsg.unit = '请选择'
        this.submitData.unitId = ''
        this.selectData.unitList = []
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.houseId = ''
        this.selectData.BuildingList = []
        this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        method: 'post',
        params: this.$http.adornParams({
          subArea: value.id
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.BuildingList = data.buildings
          } else {
          this.$toast.fail(data.msg);
          }
        })
      }
      this.CommunityShow = false
    },
    // 当前位置选择
    positionConfirm (value) {
      if (value) {
        this.selectMsg.position = value.label
        this.submitData.publicPlace = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unitId = ''
        this.selectMsg.house = '请选择'
        this.submitData.houseId = ''
      }
        this.positionShow = false
    },
    // 楼栋选择
    BuildingConfirm (value) {
      if (value) {
        this.selectMsg.Building = value.label
        this.submitData.buildingId = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unitId = ''
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.houseId = ''
      }
      this.getUnitList(value.value)
      if (!this.selectMsg.unit) {
        this.getHouseList(value.value, 1)
      }
      this.BuildingShow = false
    },
    // 单元选择
    unitConfirm (value) {
      if (value) {
        this.selectMsg.unit = value.label
        this.submitData.unitId = value.value
        this.selectMsg.house = '请选择'
        this.submitData.houseId = ''
      }
      this.unitShow = false
      this.getHouseList(value.value, 2)
    },
    // 房屋选择
    houseConfirm (value) {
      if(value) {
      this.selectMsg.house = value.label
      this.submitData.houseId = value.value
      }
      this.houseShow = false
    },
    onCancel() {
    },
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>